<template>
  <div class="personalMap" :class="{ showAnimation: selectedZone !== '' }">
    <div class="mapContainer">
      <svg
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 900 593"
        style="enable-background: new 0 0 900 593"
        xml:space="preserve"
      >
        <g id="mapSlide" @click="selectZone($event)">
          <path
            class="Fun st0"
            d="M117.9,410.1l-67.6,23.1c51.9,65.7,140.1,116.9,247,142.2l25.7-47.2C234.3,507.2,161.1,464.6,117.9,410.1z"
            :class="{
              dimOut: selectedZone !== 'Fun' && selectedZone !== '',
            }"
          ></path>
          <path
            class="Inspiring st0"
            d="M293.1,72.7L261.5,27C153.9,59.7,69.2,119.5,27.6,193.2l71.3,17.6C133.4,149.6,203.7,99.9,293.1,72.7z"
            :class="{
              dimOut: selectedZone !== 'Inspiring' && selectedZone !== '',
            }"
          ></path>
          <path
            class="Trustworthy st0"
            d="M779,179.9l67-23.8C786.1,82.8,680.7,28,554.8,8.1l-17.9,48.8C641.6,73.4,729.2,118.9,779,179.9z"
            :class="{
              dimOut: selectedZone !== 'Trustworthy' && selectedZone !== '',
            }"
          ></path>
          <path
            class="Nurturing st0"
            d="M544,534.7l19.4,48.5c131.4-22.6,239.4-83.2,295.1-163l-69.3-20.8C742.8,465.7,653.1,516,544,534.7z"
            :class="{
              dimOut: selectedZone !== 'Nurturing' && selectedZone !== '',
            }"
          ></path>
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="251.2603"
            y1="467.4629"
            x2="352.4422"
            y2="540.1874"
          >
            <stop offset="0" style="stop-color: #ec1c24"></stop>
            <stop offset="0.2293" style="stop-color: #e21a22"></stop>
            <stop offset="0.6209" style="stop-color: #c6141b"></stop>
            <stop offset="1" style="stop-color: #a60e13"></stop>
          </linearGradient>
          <path
            class="Fun st1"
            d="M180.7,388.7l-62.8,21.4c43.2,54.5,116.4,97,205.1,118.1l23.5-43.1C274.8,467.8,215.6,433.1,180.7,388.7z"
            :class="{
              dimOut: selectedZone !== 'Fun' && selectedZone !== '',
            }"
          ></path>
          <linearGradient
            id="SVGID_2_"
            gradientUnits="userSpaceOnUse"
            x1="669.0256"
            y1="138.8181"
            x2="499.8607"
            y2="70.8359"
          >
            <stop offset="0" style="stop-color: #00b17e"></stop>
            <stop offset="1" style="stop-color: #007d58"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Trustworthy' && selectedZone !== '',
            }"
            class="Trustworthy st2"
            d="M536.9,56.9l-15.8,43.3c85.5,13.5,157,51,197.4,101.2l60.6-21.5C729.2,118.9,641.6,73.4,536.9,56.9z"
          ></path>
          <linearGradient
            id="SVGID_3_"
            gradientUnits="userSpaceOnUse"
            x1="663.0578"
            y1="439.0703"
            x2="783.2114"
            y2="291.2499"
          >
            <stop offset="0" style="stop-color: #2b388f"></stop>
            <stop offset="1" style="stop-color: #0e0d50"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Nurturing' && selectedZone !== '',
            }"
            class="Nurturing st3"
            d="M526.4,490.7l17.6,44c109.1-18.7,198.8-69.1,245.2-135.3l-63.3-19C688.1,434.5,615.1,475.6,526.4,490.7z"
          ></path>
          <linearGradient
            id="SVGID_4_"
            gradientUnits="userSpaceOnUse"
            x1="238.7217"
            y1="151.5906"
            x2="139.12"
            y2="134.1998"
          >
            <stop offset="0" style="stop-color: #f6921e"></stop>
            <stop offset="0.3109" style="stop-color: #f5881f"></stop>
            <stop offset="0.8423" style="stop-color: #f26c21"></stop>
            <stop offset="1" style="stop-color: #f16322"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Inspiring' && selectedZone !== '',
            }"
            class="Inspiring st4"
            d="M321.5,113.6l-28.4-40.9c-89.4,27.2-159.7,76.9-194.2,138.1l65.8,16.3C192.3,176.9,249.1,136.1,321.5,113.6z"
          ></path>
          <path
            :class="{
              dimOut: selectedZone !== 'Interesting' && selectedZone !== '',
            }"
            class="Interesting st0"
            d="M75.7,296.4c0-30.1,8.2-58.9,23.2-85.6l-71.3-17.6c-18.1,32.1-28,66.9-28,103.2c0,49.3,18.3,95.9,50.7,136.8
                              l67.6-23.1C91,376.1,75.7,337.4,75.7,296.4z"
          ></path>
          <path
            :class="{
              dimOut: selectedZone !== 'Familiar' && selectedZone !== '',
            }"
            class="Familiar st0"
            d="M846,156l-67,23.8c28.3,34.7,44.4,74.4,44.4,116.5c0,36.8-12.3,71.7-34.2,103l69.3,20.8
                              c26.3-37.7,41-79.6,41-123.8C899.6,245.6,880.2,197.8,846,156z"
          ></path>
          <linearGradient
            id="SVGID_5_"
            gradientUnits="userSpaceOnUse"
            x1="777.3427"
            y1="392.1554"
            x2="764.9913"
            y2="136.0698"
          >
            <stop offset="0" style="stop-color: #999999"></stop>
            <stop offset="1" style="stop-color: #737373"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Familiar' && selectedZone !== '',
            }"
            class="Familiar st5"
            d="M754,295.9c0,30.2-10,58.8-28,84.6l63.3,19c22-31.4,34.2-66.3,34.2-103c0-42.2-16.1-81.8-44.4-116.5
                              l-60.6,21.5C741.1,229.6,754,261.7,754,295.9z"
          ></path>
          <linearGradient
            id="SVGID_6_"
            gradientUnits="userSpaceOnUse"
            x1="139.078"
            y1="276.452"
            x2="122.478"
            y2="365.776"
          >
            <stop offset="0" style="stop-color: #f9ec31"></stop>
            <stop offset="0.4074" style="stop-color: #f7e327"></stop>
            <stop offset="1" style="stop-color: #f4cf10"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Interesting' && selectedZone !== '',
            }"
            class="Interesting st6"
            d="M146.5,295.9c0-24.2,6.4-47.3,18.2-68.8l-65.8-16.3c-15,26.7-23.2,55.5-23.2,85.6c0,41,15.2,79.7,42.2,113.7
                              l62.8-21.4C158.9,360.9,146.5,329.4,146.5,295.9z"
          ></path>
          <path
            :class="{
              dimOut: selectedZone !== 'Friendly' && selectedZone !== '',
            }"
            class="Friendly st0"
            d="M449.6,542.7c-44.4,0-87-5.1-126.5-14.5l-25.7,47.2c47.6,11.3,98.8,17.4,152.3,17.4c39.3,0,77.5-3.3,113.8-9.6
                              L544,534.7C513.8,539.9,482.2,542.7,449.6,542.7z"
          ></path>
          <path
            :class="{
              dimOut: selectedZone !== 'Knowledgeable' && selectedZone !== '',
            }"
            class="Knowledgeable st0"
            d="M449.6,50.1c30.1,0,59.3,2.3,87.3,6.8l17.9-48.8C521,2.8,485.8,0,449.6,0C382.4,0,318.7,9.7,261.5,27
                              l31.7,45.6C340.7,58.2,393.7,50.1,449.6,50.1z"
          ></path>
          <linearGradient
            id="SVGID_7_"
            gradientUnits="userSpaceOnUse"
            x1="410.1664"
            y1="514.3226"
            x2="661.5415"
            y2="485.8651"
          >
            <stop offset="0" style="stop-color: #90278e"></stop>
            <stop offset="1" style="stop-color: #5b0059"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Friendly' && selectedZone !== '',
            }"
            class="Friendly st7"
            d="M450.2,497.1c-36.4,0-71.3-4.2-103.7-12L323,528.2c39.5,9.4,82.1,14.5,126.5,14.5c32.6,0,64.3-2.8,94.4-7.9
                              l-17.6-44C502,494.9,476.5,497.1,450.2,497.1z"
          ></path>
          <linearGradient
            id="SVGID_8_"
            gradientUnits="userSpaceOnUse"
            x1="425.3801"
            y1="85.9758"
            x2="390.5985"
            y2="14.8318"
          >
            <stop offset="0" style="stop-color: #804022"></stop>
            <stop offset="0.2965" style="stop-color: #76381b"></stop>
            <stop offset="0.8034" style="stop-color: #5a230a"></stop>
            <stop offset="1" style="stop-color: #4e1902"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Knowledgeable' && selectedZone !== '',
            }"
            class="Knowledgeable st8"
            d="M450.2,94.7c24.4,0,48.1,1.9,70.8,5.5l15.8-43.3c-28-4.4-57.3-6.8-87.3-6.8c-55.9,0-108.8,8.1-156.4,22.5
                              l28.4,40.9C360.6,101.5,404.2,94.7,450.2,94.7z"
          ></path>
          <linearGradient
            id="SVGID_9_"
            gradientUnits="userSpaceOnUse"
            x1="328.718"
            y1="378.0399"
            x2="410.9282"
            y2="303.7345"
          >
            <stop offset="0" style="stop-color: #f7aea5"></stop>
            <stop offset="0.2878" style="stop-color: #f6a4a0"></stop>
            <stop offset="0.7797" style="stop-color: #f38894"></stop>
            <stop offset="1" style="stop-color: #f27a8d"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Fun' && selectedZone !== '',
            }"
            class="Fun st9"
            d="M346.5,332.1l-165.8,56.6c34.9,44.4,94.1,79.1,165.9,96.4l63.6-116.7C382.6,361.9,359.9,348.9,346.5,332.1z"
          ></path>
          <linearGradient
            id="SVGID_10_"
            gradientUnits="userSpaceOnUse"
            x1="588.5313"
            y1="193.7156"
            x2="537.94"
            y2="263.2785"
          >
            <stop offset="0" style="stop-color: #58be96"></stop>
            <stop offset="1" style="stop-color: #4aa481"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Trustworthy' && selectedZone !== '',
            }"
            class="Trustworthy st10"
            d="M552.7,260.3l165.7-58.9c-40.4-50.2-111.8-87.7-197.4-101.2l-44.7,122.1C509.3,227.3,537,241.4,552.7,260.3z"
          ></path>
          <linearGradient
            id="SVGID_11_"
            gradientUnits="userSpaceOnUse"
            x1="578.0821"
            y1="377.0156"
            x2="527.4916"
            y2="315.3584"
          >
            <stop offset="0" style="stop-color: #8ac5eb"></stop>
            <stop offset="1" style="stop-color: #6a96b4"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Nurturing' && selectedZone !== '',
            }"
            class="Nurturing st11"
            d="M478.4,370.7l48,120.1c88.7-15.2,161.7-56.2,199.5-110.3l-170.4-51.1C540.7,349.7,512.5,365.1,478.4,370.7z"
          ></path>
          <linearGradient
            id="SVGID_12_"
            gradientUnits="userSpaceOnUse"
            x1="289.8135"
            y1="206.0066"
            x2="373.605"
            y2="270.0359"
          >
            <stop offset="0" style="stop-color: #fbb973"></stop>
            <stop offset="0.327" style="stop-color: #fab269"></stop>
            <stop offset="0.8861" style="stop-color: #f8a04d"></stop>
            <stop offset="1" style="stop-color: #f89c47"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Inspiring' && selectedZone !== '',
            }"
            class="Inspiring st12"
            d="M400.6,227.5l-79-113.9c-72.4,22.5-129.2,63.3-156.8,113.5l175.4,43.4C350.8,251.5,372.7,236,400.6,227.5z"
          ></path>
          <linearGradient
            id="SVGID_13_"
            gradientUnits="userSpaceOnUse"
            x1="713.0676"
            y1="290.9235"
            x2="616.726"
            y2="290.9235"
          >
            <stop offset="0" style="stop-color: #c5c7c9"></stop>
            <stop offset="1" style="stop-color: #a6a6a6"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Familiar' && selectedZone !== '',
            }"
            class="Familiar st13"
            d="M566.8,296.7c0,11.7-4,22.8-11.2,32.7l170.4,51.1c18-25.7,28-54.4,28-84.6c0-34.2-12.9-66.3-35.5-94.5
                              l-165.7,58.9C561.7,271.1,566.8,283.5,566.8,296.7z"
          ></path>
          <linearGradient
            id="SVGID_14_"
            gradientUnits="userSpaceOnUse"
            x1="244.1125"
            y1="304.9641"
            x2="436.9913"
            y2="297.0592"
          >
            <stop offset="0" style="stop-color: #faf183"></stop>
            <stop offset="0.1646" style="stop-color: #faec79"></stop>
            <stop offset="0.4469" style="stop-color: #f8de5d"></stop>
            <stop offset="0.8104" style="stop-color: #f6c931"></stop>
            <stop offset="1" style="stop-color: #f5bc17"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Interesting' && selectedZone !== '',
            }"
            class="Interesting st14"
            d="M333.1,296.7c0-9.2,2.5-18,7.1-26.2l-175.4-43.4c-11.8,21.5-18.2,44.6-18.2,68.8c0,33.5,12.3,65,34.2,92.8
                              l165.8-56.6C337.9,321.5,333.1,309.5,333.1,296.7z"
          ></path>
          <linearGradient
            id="SVGID_15_"
            gradientUnits="userSpaceOnUse"
            x1="437.4375"
            y1="426.6608"
            x2="439.809"
            y2="277.2582"
          >
            <stop offset="0" style="stop-color: #c59bc7"></stop>
            <stop offset="0.1249" style="stop-color: #bd91be"></stop>
            <stop offset="0.3392" style="stop-color: #a775a5"></stop>
            <stop offset="0.6169" style="stop-color: #84497c"></stop>
            <stop offset="0.9442" style="stop-color: #540b44"></stop>
            <stop offset="1" style="stop-color: #4b003a"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Friendly' && selectedZone !== '',
            }"
            class="Friendly st15"
            d="M449.9,372.9c-14,0-27.4-1.6-39.8-4.5l-63.6,116.7c32.4,7.8,67.3,12,103.7,12c26.3,0,51.8-2.2,76.2-6.4
                              l-48-120.1C469.3,372.1,459.7,372.9,449.9,372.9z"
          ></path>
          <linearGradient
            id="SVGID_16_"
            gradientUnits="userSpaceOnUse"
            x1="423.8921"
            y1="80.663"
            x2="408.115"
            y2="240.4053"
          >
            <stop offset="0" style="stop-color: #aa7e62"></stop>
            <stop offset="0.4751" style="stop-color: #a5745d"></stop>
            <stop offset="1" style="stop-color: #9e6356"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Knowledgeable' && selectedZone !== '',
            }"
            class="Knowledgeable st16"
            d="M321.5,113.6l79,113.9c15-4.6,31.7-7.1,49.3-7.1c9.1,0,17.9,0.7,26.4,2l44.7-122.1
                              c-22.7-3.6-46.4-5.5-70.8-5.5C404.2,94.7,360.6,101.5,321.5,113.6z"
          ></path>
          <linearGradient
            id="SVGID_17_"
            gradientUnits="userSpaceOnUse"
            x1="346.4608"
            y1="332.8404"
            x2="448.8942"
            y2="332.8404"
          >
            <stop offset="0" style="stop-color: #fbd5ce"></stop>
            <stop offset="0.3812" style="stop-color: #facbc8"></stop>
            <stop offset="1" style="stop-color: #f7b1ba"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Fun' && selectedZone !== '',
            }"
            class="Fun st17"
            d="M448.6,297.3l-102.2,34.9c13.5,16.7,36.2,29.8,63.7,36.3l38.7-71.1L448.6,297.3z"
          ></path>
          <linearGradient
            id="SVGID_18_"
            gradientUnits="userSpaceOnUse"
            x1="497.2629"
            y1="260.365"
            x2="451.4155"
            y2="310.9553"
          >
            <stop offset="0" style="stop-color: #b0dcc8"></stop>
            <stop offset="1" style="stop-color: #85a799"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Trustworthy' && selectedZone !== '',
            }"
            class="Trustworthy st18"
            d="M552.7,260.3c-15.7-18.9-43.4-33-76.3-38L449,297.2L552.7,260.3z"
          ></path>
          <linearGradient
            id="SVGID_19_"
            gradientUnits="userSpaceOnUse"
            x1="504.2613"
            y1="339.0582"
            x2="407.032"
            y2="255.2671"
          >
            <stop offset="0" style="stop-color: #cbe2f5"></stop>
            <stop offset="1" style="stop-color: #8c9dac"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Nurturing' && selectedZone !== '',
            }"
            class="Nurturing st19"
            d="M478.4,370.7c34.2-5.6,62.4-21,77.2-41.3l-106.5-31.9L478.4,370.7z"
          ></path>
          <linearGradient
            id="SVGID_20_"
            gradientUnits="userSpaceOnUse"
            x1="382.1138"
            y1="249.9289"
            x2="408.1996"
            y2="321.0721"
          >
            <stop offset="0" style="stop-color: #ffe1c1"></stop>
            <stop offset="0.2579" style="stop-color: #fed8b7"></stop>
            <stop offset="0.6987" style="stop-color: #fbc19b"></stop>
            <stop offset="1" style="stop-color: #f9ae85"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Inspiring' && selectedZone !== '',
            }"
            class="Inspiring st20"
            d="M340.1,270.5l108.5,26.8l0.1,0l0.2-0.1l-48.4-69.7C372.7,236,350.8,251.5,340.1,270.5z"
          ></path>
          <linearGradient
            id="SVGID_21_"
            gradientUnits="userSpaceOnUse"
            x1="536.7336"
            y1="295.3947"
            x2="417.3684"
            y2="299.3472"
          >
            <stop offset="0" style="stop-color: #dbdcdd"></stop>
            <stop offset="1" style="stop-color: #919191"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Familiar' && selectedZone !== '',
            }"
            class="Familiar st21"
            d="M449,297.2l0.1,0.1l0,0l106.5,31.9c7.2-9.9,11.2-21,11.2-32.7c0-13.2-5.1-25.5-14.1-36.4L449,297.2L449,297.2
                              z"
          ></path>
          <linearGradient
            id="SVGID_22_"
            gradientUnits="userSpaceOnUse"
            x1="357.4624"
            y1="288.4954"
            x2="460.2253"
            y2="355.6866"
          >
            <stop offset="0" style="stop-color: #f3f4c5"></stop>
            <stop offset="0.1294" style="stop-color: #f3f3bb"></stop>
            <stop offset="0.3513" style="stop-color: #f5f29f"></stop>
            <stop offset="0.639" style="stop-color: #f6f073"></stop>
            <stop offset="0.9781" style="stop-color: #f9ec35"></stop>
            <stop offset="1" style="stop-color: #f9ec31"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Interesting' && selectedZone !== '',
            }"
            class="Interesting st22"
            d="M340.1,270.5c-4.6,8.2-7.1,17-7.1,26.2c0,12.8,4.8,24.9,13.4,35.5l102.2-34.9L340.1,270.5z"
          ></path>
          <linearGradient
            id="SVGID_23_"
            gradientUnits="userSpaceOnUse"
            x1="444.3013"
            y1="364.2184"
            x2="445.0917"
            y2="261.4582"
          >
            <stop offset="0" style="stop-color: #dcc6e0"></stop>
            <stop offset="1" style="stop-color: #9d8ea2"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Friendly' && selectedZone !== '',
            }"
            class="Friendly st23"
            d="M448.9,297.4L448.9,297.4l-38.8,71.1c12.4,2.9,25.8,4.5,39.8,4.5c9.8,0,19.3-0.8,28.4-2.3l-29.3-73.3
                              L448.9,297.4z"
          ></path>
          <linearGradient
            id="SVGID_24_"
            gradientUnits="userSpaceOnUse"
            x1="436.4919"
            y1="193.289"
            x2="443.6061"
            y2="307.1171"
          >
            <stop offset="0" style="stop-color: #d9c4b6"></stop>
            <stop offset="0.2612" style="stop-color: #d1baae"></stop>
            <stop offset="0.7077" style="stop-color: #bc9e99"></stop>
            <stop offset="1" style="stop-color: #ac8989"></stop>
          </linearGradient>
          <path
            :class="{
              dimOut: selectedZone !== 'Knowledgeable' && selectedZone !== '',
            }"
            class="Knowledgeable st24"
            d="M449,297.2l27.4-74.8c-8.5-1.3-17.3-2-26.4-2c-17.6,0-34.4,2.6-49.3,7.1L449,297.2L449,297.2z"
          ></path>
          <polyline
            class="st25"
            points="434.9,371.6 435,371.6 435,371.6  "
          ></polyline>
          <polyline
            class="st25"
            points="430.9,371.2 431.1,371.2 431.1,371.3  "
          ></polyline>
        </g>
        <g id="mapLabel">
          <text
            transform="matrix(1 0 0 1 357.2705 136.0542)"
            class="Knowledgeable st26 st27"
          >
            KNOWLEDGEABLE
          </text>
          <text
            transform="matrix(1 0 0 1 404.708 466.0542)"
            class="Friendly st26 st27"
          >
            FRIENDLY
          </text>
          <text
            transform="matrix(1 0 0 1 528.2744 402.0542)"
            class="Nurturing st26 st27"
          >
            NURTURING
          </text>
          <text
            transform="matrix(1 0 0 1 627.376 295.0542)"
            class="Familiar st26 st27"
          >
            FAMILIAR
          </text>
          <text
            transform="matrix(1 0 0 1 511.0557 187.0542)"
            class="Trustworthy st26 st27"
          >
            TRUSTWORTHY
          </text>
          <text
            transform="matrix(1 0 0 1 238.376 187.0542)"
            class="Inspiring st26 st27"
          >
            INSPIRING
          </text>
          <text
            transform="matrix(1 0 0 1 184.4893 301.0542)"
            class="Interesting st26 st27"
          >
            INTERESTING
          </text>
          <text
            transform="matrix(1 0 0 1 292.8291 399.0542)"
            class="Fun st26 st27"
          >
            FUN
          </text>
          `
        </g>
      </svg>
    </div>
    <div class="attributes" v-show="selectedZone !== ''">
      <p class="zoneName" :class="{ visuallyHidden: !showZoneName }">
        {{ selectedZone }} Zone
      </p>
      <div
        class="attributeTag"
        v-for="attr in zoneAttributes"
        :key="attr.attributeID"
      >
        <p class="attrLabel">
          <a-checkbox v-model="attr.selected">
            <span class="title">{{ attr.AttributeLabel }}</span>
          </a-checkbox>
          <img src="/images/icons/core.svg" v-show="attr.ringName === 'Core'" />
          <img
            src="/images/icons/diff.svg"
            v-show="attr.ringName === 'Differentiator'"
          />
        </p>
        <p class="synonyms" v-show="!attr.showFull">
          {{ attr.synonymLabel | shortVersion }} ...
          <span class="more" @click="attr.showFull = true">[+]</span>
        </p>
        <p class="synonyms" v-show="attr.showFull">
          {{ attr.synonymLabel }}
          <span class="more" @click="attr.showFull = false">[-]</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonaMap",
  props: {
    personaAttrs: Array,
  },
  data() {
    return {
      selectedZone: "",
      zoneAttributes: [],
      showZoneName: false,
    };
  },
  filters: {
    shortVersion(value) {
      let synonyms = value.split(",").slice(0, 3);
      return synonyms.toString();
    },
  },
  methods: {
    selectZone(event) {
      this.selectedZone = event.target.classList[0];
    },
  },
  watch: {
    selectedZone(newVal, oldVal) {
      if (newVal !== "") {
        this.zoneAttributes = this.personaAttrs
          .filter((attr) => attr.zoneName === newVal)
          .sort((a, b) => {
            return a.ringName.localeCompare(b.ringName);
          });
      }

      if (oldVal === "") {
        const mapSlide = document.querySelector("#mapSlide");
        mapSlide.addEventListener(
          "animationend",
          () => {
            document.querySelector(".personalMap").classList.add("showGrid");

            document
              .querySelector(".personalMap")
              .classList.remove("showAnimation");
            this.showZoneName = true;
          },
          { once: true }
        );
      }
    },
  },
  activated() {
    this.selectedZone = "";
    this.zoneAttributes = [];
    this.showZoneName = false;
  },
};
</script>

<style lang="scss" scoped>
.mapContainer {
  padding: 1em;

  #mapSlide {
    max-width: 100%;
  }

  svg {
    margin-bottom: 30px;
    transition: all 0.5s;

    .st0 {
      fill: #231f20;
      stroke: #ffffff;
    }

    .st1 {
      fill: url(#SVGID_1_);
    }

    .st2 {
      fill: url(#SVGID_2_);
    }

    .st3 {
      fill: url(#SVGID_3_);
    }

    .st4 {
      fill: url(#SVGID_4_);
    }

    .st5 {
      fill: url(#SVGID_5_);
    }

    .st6 {
      fill: url(#SVGID_6_);
    }

    .st7 {
      fill: url(#SVGID_7_);
    }

    .st8 {
      fill: url(#SVGID_8_);
    }

    .st9 {
      fill: url(#SVGID_9_);
    }

    .st10 {
      fill: url(#SVGID_10_);
    }

    .st11 {
      fill: url(#SVGID_11_);
    }

    .st12 {
      fill: url(#SVGID_12_);
    }

    .st13 {
      fill: url(#SVGID_13_);
    }

    .st14 {
      fill: url(#SVGID_14_);
    }

    .st15 {
      fill: url(#SVGID_15_);
    }

    .st16 {
      fill: url(#SVGID_16_);
    }

    .st17 {
      fill: url(#SVGID_17_);
    }

    .st18 {
      fill: url(#SVGID_18_);
    }

    .st19 {
      fill: url(#SVGID_19_);
    }

    .st20 {
      fill: url(#SVGID_20_);
    }

    .st21 {
      fill: url(#SVGID_21_);
    }

    .st22 {
      fill: url(#SVGID_22_);
    }

    .st23 {
      fill: url(#SVGID_23_);
    }

    .st24 {
      fill: url(#SVGID_24_);
    }

    .st25 {
      fill: none;
    }

    .st26 {
      font-family: "Roboto";
    }

    .st27 {
      font-size: 16px;
    }
  }
}

.personalMap {
  display: grid;
  grid-template-columns: 1fr 1px;
}

.personalMap.showAnimation {
  #mapSlide {
    animation-duration: 0.4s;
    animation-name: mapScale;
    animation-timing-function: ease-out;
    // transform-origin: center;
  }

  #mapLabel {
    display: none;
  }
}

.personalMap.showGrid {
  grid-template-columns: 1fr 1fr;
  height: 100%;
  overflow: hidden;

  svg {
    padding-top: 2em;
  }
}

.attributes {
  padding-right: 1em;
  // padding: 3.5em 1em 1em;
  padding: 1em;
  height: 100%;
  // overflow: auto;
  .ant-tag,
  .ant-checkbox-wrapper {
    // padding: 7px;
    margin-bottom: 2px;
    cursor: pointer;
    white-space: normal;
    display: inline-block;

    .title {
      // font-size: 1.2em;
      font-weight: 600;
      text-transform: capitalize;
    }

    small {
      font-size: 0.9em;
      font-weight: 400;
      padding-left: 4px;
      // text-transform: lowercase;
    }
  }

  .attributeTag {
    display: block;

    .synonyms {
      padding-left: 25px;
    }

    .more {
      // text-decoration: underline;
      // font-style: italic;
      cursor: pointer;
      &:hover {
        color: var(--hsblue);
      }
    }
  }

  .attrLabel {
    img {
      height: 1em;
    }
    display: flex;
    margin-bottom: 0;
  }
}

.zoneName {
  // text-align: center;
  height: 25px;
  line-height: 25px;
  font-weight: 700;
  font-size: 1.2em;
  margin-bottom: 1.5em;
}

@keyframes mapScale {
  from {
    transform: scale(1);
    // width: 100%;
  }
  to {
    transform: scale(0.5);
    // width: 50%;
  }
}

#mapLabel text {
  pointer-events: none;
}

#mapSlide {
  cursor: pointer;
}

.dimOut {
  filter: grayscale(100%) opacity(10%);
}
</style>

<style>
.ant-tabs .ant-tabs-top-content {
  height: 100%;
  overflow: hidden;
}

/* .ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane { */
.ant-tabs-tabpane-active {
  height: calc(100% - 40px);
  overflow: hidden;
}

.ant-tabs-bar {
  margin-bottom: 0;
}
</style>
