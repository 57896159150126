var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"authoring-wrapper"},[_c('div',{staticClass:"flex h-full"},[_c('ProgressBar',{attrs:{"stepIndex":_vm.stepIndex,"surveyID":_vm.surveyID,"stepsType":_vm.surveyTypeID === 102
          ? 'Reticle Alignment Test'
          : _vm.$route.name !== 'pack-test-brand-strategy'
          ? 'video'
          : 'pack'}}),_c('section',{staticClass:"wrapper"},[_c('a-breadcrumb',{staticClass:"breadcrum breadcrum--authoring"},[_c('a-breadcrumb-item',[_c('a',{attrs:{"href":"/"}},[_vm._v(_vm._s(_vm.client.ClientName))])]),_c('a-breadcrumb-item',[_c('a',{attrs:{"href":"/products"}},[_vm._v("Products")])]),_c('a-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.$route.meta.surveyTitle))])],1),_c('div',{staticClass:"wrapper__inner"},[_c('div',{staticClass:"page-title"},[_c('icon-base',{attrs:{"width":32,"height":33,"viewBox1":22,"viewBox2":23,"iconName":"pie","iconColor":"transparent"}},[_c('icon-pie')],1),_vm._m(0)],1),_c('div',{staticClass:"box-wrapper"},[_c('div',{staticClass:"section-info"},[_c('p',{staticClass:"font-size-14"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s3-persona'].quiz)}}),_c('a-tooltip',{attrs:{"placement":"topRight"}},[_c('template',{slot:"title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s3-persona'].tip)},on:{"click":_vm.showModal}})]),_c('a-icon',{staticClass:"ml-5",attrs:{"type":"info-circle"}})],2)],1),_c('p',{staticClass:"font-size-14",domProps:{"innerHTML":_vm._s(_vm.langObj['s3-persona'].brief)}}),_c('p',{staticClass:"font-size-14 mb-20"},[_vm._v(" Why do we do this? Click "),_c('a',{staticClass:"underline",on:{"click":_vm.showModal}},[_vm._v("here")]),_vm._v(" to learn more. ")])]),_c('div',{staticClass:"persona"},[_c('div',{staticClass:"optionPanel"},[_c('a-tabs',{attrs:{"type":"card","default-active-key":"1"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"Map View [recommended]"}},[_c('PersonaMap',{attrs:{"personaAttrs":_vm.personaAttrs}})],1),_c('a-tab-pane',{key:"2",attrs:{"tab":"List View [simpler]"}},[_c('PersonaList',{attrs:{"personaAttrs":_vm.personaAttrs,"loading":_vm.showLoader}})],1)],1)],1),_c('div',{staticClass:"selectedPanel"},[(!_vm.showLoader)?_c('div',{staticClass:"title desWithBtn"},[_c('span',{class:{
                    perfect: _vm.selectedStatus === 1,
                    exceed: _vm.selectedStatus === 2,
                  }},[_c('font-awesome-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedStatus === 1),expression:"selectedStatus === 1"}],attrs:{"icon":"check-circle"}}),_c('font-awesome-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedStatus === 2),expression:"selectedStatus === 2"}],attrs:{"icon":"exclamation-circle"}}),_vm._v(" "+_vm._s(_vm.selectedAttrs.length)+" Selected")],1),_c('button',{staticClass:"text-btn font-size-14",on:{"click":_vm.clearSelection}},[_vm._v(" Clear All ")])]):_vm._e(),(_vm.showLoader)?_c('div',{staticClass:"h-full flex items-center justify-center"},[_c('i',{staticClass:"circle-loader"})]):[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedAttrs.length === 0),expression:"selectedAttrs.length === 0"}],staticClass:"empty"},[_c('img',{attrs:{"src":"/images/icons/empty.svg"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedAttrs.length > 0),expression:"selectedAttrs.length > 0"}],staticClass:"attributes"},_vm._l((_vm.selectedAttrs),function(attr){return _c('a-tag',{key:attr.attributeID,attrs:{"closable":"","color":"#108ee9"},on:{"close":function($event){attr.selected = false}}},[_vm._v(" "+_vm._s(attr.AttributeLabel)+" ")])}),1)]],2)])]),_c('StepNaviBar',{attrs:{"stepIndex":_vm.stepIndex,"nextDisable":_vm.selectedStatus !== 1,"prevDisable":false,"prevBtnTooltip":"Back to Creative","nextBtnTooltip":_vm.$route.name === 'vid-ua-brand-strategy'
              ? 'Go to Customize'
              : 'Go to Key Metrics',"stepsType":_vm.surveyTypeID === 102
              ? 'Reticle Alignment Test'
              : _vm.$route.name !== 'pack-test-brand-strategy'
              ? 'video'
              : 'pack'},on:{"nextHandler":_vm.nextStep,"prevHandler":_vm.prevStep}}),_c('a-modal',{attrs:{"width":"90%","height":"90vh","centered":"","footer":null,"header":false,"wrapClassName":"ant-modal--persona-iframe"},on:{"cancel":function($event){_vm.personaPreviewModal = false}},model:{value:(_vm.personaPreviewModal),callback:function ($$v) {_vm.personaPreviewModal=$$v},expression:"personaPreviewModal"}},[_c('iframe',{attrs:{"src":"https://hotspex.com/persona/","width":"100%","height":"100%","frameborder":"0"}})])],1)],1)],1),_c('CompletionProgress',{attrs:{"checkList":_vm.quizs}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title__text"},[_c('h1',[_vm._v("Brand Strategy")]),_c('span',[_vm._v("Key emotional attributes")])])}]

export { render, staticRenderFns }